import React, { useEffect, useState } from 'react';

import Navigation from './Navigation';
import Work from './Work';

import './App.scss';

function App() {
  const [data, setData] = useState([]);


  useEffect(() => {
    fetch('data.json')
      .then(json => json.json())
      .then(parsed => setData(parsed))
      .catch(err => console.log(err));
  }, []);

  return (
    <div className="App">
      <div className="texture" />
      <Navigation />
      <p className="about" dangerouslySetInnerHTML={{ __html: data?.about }} />
      <div className="workWrapper">
        {data?.work?.length > 0 ? data?.work.map(({ title, client, description, technologies, gallery }) => (<Work title={title} client={client} description={description} technologies={technologies} gallery={gallery} key={title} />)) : null}
      </div>
    </div>
  );
}

export default App;

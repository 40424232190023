import React, { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
// import { useInView } from 'react-intersection-observer';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import useOnScreen from '../hooks/useOnScreen';

import './Work.scss';

function Work({ title, client, description, technologies, gallery }) {
  const swiperWrapper = useRef();
  const videoIndexes = useRef([]);
  // const { ref, inView, entry } = useInView({
  //   trackVisibility: true,
  //   delay: 500,
  //   threshold: 0.9,
  // });
  const stopAllVideos = () => {
    const videos = swiperWrapper.current.querySelectorAll('video');
    videos.forEach(video => video.pause())
    // if (videoIndexes.current.includes(e?.activeIndex)) {
    // }
  }

  useEffect(() => {
    let videos = swiperWrapper.current.querySelectorAll("video");
    videos.forEach((video) => {
      let observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (
              entry.intersectionRatio !== 1 &&
              !video.paused
            ) {
              video.pause();
            } else if (video.paused) {
              // video.play();
            }
          });
        },
        { threshold: 0.2 }
      );
      observer.observe(video);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="item" key={title}>
      <div className="showcase" ref={swiperWrapper} data-title={title}>
        <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        navigation={gallery.length > 1}
        modules={[Pagination, Navigation]}
        onSlideChange={(e) => {
          stopAllVideos();
        }}
        className="workSwiper"
      >
        {gallery ? gallery.map(({ src, type }, index) => {
          const isVideo = type === 'video';
          if (isVideo) videoIndexes.current.push(index);
          const key = `${title}-gallery-item-${index}`;
          return <SwiperSlide type={type} key={key}>
            {isVideo ? <video src={src} width="100%" height="auto" controls /> : <img src={src} width="100%" height="auto" alt={`${title} creative`} />}
          </SwiperSlide>
        }) : null}
      </Swiper>
      </div>
      <div className="info">
        <p className="title">{title}</p>
        <div className="technologies">
          <div className="background skewed-background" />
          {technologies.map((technology, index) => {
            return <>
                <span className="technology" key={`${title}-${technology}-${index}`}>{technology}</span>
                {index < technologies.length - 1 ? <span className="divider">/</span> : null}
              </>
          })}
        </div>
        <p className="client">{client}</p>
        <p className="description" dangerouslySetInnerHTML={{__html: description}} />
      </div>
    </div>
  );
}

export default Work;

import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
// import { SplitText } from 'gsap/SplitText';

import styles from '../styles/_variables.scss';

import './Navigation.scss';

// gsap.registerPlugin(SplitText);

const LinkedInButton = () => {
  return <a className="linkedIn icon" href="https://www.linkedin.com/in/michaeltrueman/" target="_blank" rel="noreferrer">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
      <path fill="currentColor" d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5c0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7c-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5c67.2 0 79.7 44.3 79.7 101.9V416z"></path>
    </svg>
  </a>
}

const githubButton = () => {
  return <a className="github icon" href="https://github.com/mbtrueman/" target="_blank" rel="noreferrer">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
      <path d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"/>
    </svg>
  </a>
}

const emailButton = () => {
  return <a className="email icon" href="mailto:mbtrueman@gmail.com" target="_blank" rel="noreferrer">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M256 64C150 64 64 150 64 256s86 192 192 192c17.7 0 32 14.3 32 32s-14.3 32-32 32C114.6 512 0 397.4 0 256S114.6 0 256 0S512 114.6 512 256v32c0 53-43 96-96 96c-29.3 0-55.6-13.2-73.2-33.9C320 371.1 289.5 384 256 384c-70.7 0-128-57.3-128-128s57.3-128 128-128c27.9 0 53.7 8.9 74.7 24.1c5.7-5 13.1-8.1 21.3-8.1c17.7 0 32 14.3 32 32v80 32c0 17.7 14.3 32 32 32s32-14.3 32-32V256c0-106-86-192-192-192zm64 192a64 64 0 1 0 -128 0 64 64 0 1 0 128 0z"/>
    </svg>
  </a>
}

// const resumeButton = () => {
//   return <a className="resume icon" href="./MichaelTrueman_Resume.pdf" target="_blank" rel="noreferrer">

//   </a>
// }

function Navigation() {
  // const comp = useRef(); // create a ref for the root level element (for scoping)
  const name = useRef();
  const mouse = { x: 0, y: 0 };

  // useLayoutEffect(() => {  
  //   // create our context. This function is invoked immediately and all GSAP animations and ScrollTriggers created during the execution of this function get recorded so we can revert() them later (cleanup)
  //   let ctx = gsap.context(() => {
  //     gsap.to(title.current, { rotation: 360 });
  //   }, comp);

  //   return () => ctx.revert(); // cleanup

  // }, []);

  useEffect(() => {
    const tabletMin = parseInt(styles.tabletMin)
    const handleMouseMove = (e) => {
      if (window.innerWidth < tabletMin) return gsap.to(name.current, 0.3, { textShadow: "0 0 0 rgba(68,68,68,0)" });;
      mouse.x = e.clientX || e.pageX;
      mouse.y = e.clientY || e.pageY
      const posX = parseInt(mouse.x * 0.9); // (mouse.x) - (window.innerWidth / 2));
      const posY = parseInt(mouse.y * 0.9) // (mouse.y) - (window.innerHeight / 2));

      gsap.to(name.current, 0.3, { textShadow: posX / -150 + "px " + posY / -100 + "px 6px rgba(68,68,68,0.4)" });
    }

    // const tl = gsap.timeline(),
    // mySplitText = new SplitText(name.current, { type: "words,chars" }),
    // chars = mySplitText.chars; //an array of all the divs that wrap each character

    // gsap.set(name.current, { perspective: 400 });

    // tl.from(chars, {
    //   duration: 0.8,
    //   opacity: 0,
    //   scale: 0,
    //   y: -80,
    //   // rotationX: 180,
    //   transformOrigin: "50% 50% 50%",
    //   ease: "back",
    //   delay: 1,
    //   stagger: 0.03
    // });

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <nav className="navigation">
      <div className="title">
        <div className="details">
          <p className="name" ref={name}>Michael<span>&nbsp;</span>Trueman</p>
          <div className="role">
            <p className="text">Creative Technologist</p>
            <div className="icons">
              {LinkedInButton()}
              {githubButton()}
              {emailButton()}
            </div>
            <div className="background skewed-background" />
          </div>
        </div>
      </div>
      {/* <div className="contact">
        {LinkedInButton()}
        {githubButton()}
      </div> */}
    </nav>
  )
}

export default Navigation;